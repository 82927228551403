<template>
  <div id="bespoke">
    <header>
      <h1>Services</h1>
    </header>
    <div class="services">
      <h2>Services of the NRoom are;</h2>
      <ul class="services-grid">
        <li class="service">Outright Script sale (all rights transferred)</li>
        <li class="service">Licensing rights (co-production options)</li>
        <li class="service">Hybrid rights transfer options</li>
      </ul>
      <p class="note">
        Also you can have a story removed from public viewing via our
        exclusivity option, available after registration; select duration and
        contact us
      </p>
    </div>
    <div class="bespoke">
      <h2>Hi, {{ user ? user.displayName : null }}</h2>
      <p>
        We also offer bespoke services to clients who require made to order
        scripts. This service is available to you after 3 steps;
      </p>
      <ol class="steps">
        <li class="step">
          Provide the basic details required by filling out the form below
        </li>
        <li class="step">We carry out client authentication</li>
        <li class="step">
          We contact you and agree a negotiated arrangement for your ordered
          script.
        </li>
      </ol>
      <p class="note">
        Our bespoke service includes the possibility for a no-cost option on
        order. Try it out now.
      </p>
    </div>
    <div class="bespoke">
      <form action="" @submit.prevent="submitForm">
        <h3>Your idea details</h3>
        <div class="input-group">
          <input name="title" type="text" id="play-title" required />
          <label for="play-title">Do you have a title for your story?</label>
        </div>
        <div class="two-column">
          <div class="input-group">
            <select name="media" id="play-media" required>
              <option value="TV">TV</option>
              <option value="Film">Film</option>
            </select>
            <label for="play-media"
              >Is your story for a TV series or a film?</label
            >
          </div>
          <div class="input-group">
            <input
              name="genre"
              list="genres"
              type="text"
              id="play-genre"
              required
            />
            <label for="play-genre">Which genre is your story?</label>
            <datalist id="genres">
              <option
                v-for="genre in genreOptions"
                :key="genre.id"
                :value="genre.name"
              >
                {{ genre.name }}
              </option>
            </datalist>
          </div>
        </div>
        <div class="input-group">
          <textarea
            name="character_description"
            id="play-character-description"
          />
          <label for="play-character-description"
            >Do you have any specific characters in mind for your story? (If
            yes, what can you tell us about the characters?)</label
          >
        </div>
        <div class="two-column">
          <div class="input-group">
            <input name="setting" type="text" id="play-setting" required />
            <label for="play-setting"
              >Do you have a preference for the time/period your story is set
              in?</label
            >
          </div>
          <div class="input-group">
            <input name="audience" type="text" id="play-audience" required />
            <label for="play-audience"
              >Who is your story’s target audience?</label
            >
          </div>
        </div>
        <div v-if="!user" class="input-group">
          <input name="email" type="email" id="user-email" required />
          <label for="user-email">How do we contact you?</label>
        </div>
        <button class="btn" :class="{ loading }" type="submit">
          <template v-if="!loading">Submit Form</template>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import genreOptions from "@/data/genres.json";
import axios from "axios";
export default {
  data() {
    return {
      genreOptions,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async submitForm(event) {
      this.loading = true;
      const form = {};
      if (this.user) {
        form.username = this.user.displayName;
        form.email = this.user.email;
        form["phone number"] = this.user.phoneNumber;
      }
      const formData = new FormData(event.target);
      for (var key of formData.keys()) {
        form[key.replace("_", " ")] = formData.get(key);
      }
      try {
        await axios.post("https://formspree.io/f/mrgrkdpr", {
          form,
        });
        event.target.reset();
      } catch (err) {
        throw new Error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#bespoke {
  color: #221f1f;
}

header {
  height: 212px;
  display: grid;
  place-items: center;
  background: url(~@/assets/images/bespoke.jpg);
  color: #fafafa;

  h1 {
    font-size: 3.2rem;
  }
}

h2 {
  font-size: 2.4rem;
  font-weight: 700;
}

.bespoke {
  background: #fafafa;
  padding: 32px 4vw;
  border-bottom: 0.5px solid lightgray;

  p {
    font-size: 1.7rem;

    @media (min-width: 764px) {
      max-width: 60%;
    }
  }

  ol {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin-top: 16px;
    grid-gap: 15px;
    counter-reset: section;

    li {
      background: #fff;
      border-radius: 4px;
      box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1);
      padding: 16px;
      font-size: 1.4rem;
      font-weight: 500;
      display: grid;
      grid-template-columns: 10px auto;
      grid-gap: 15px;
      align-items: center;

      &::before {
        counter-increment: section;
        /* content: counters(section); */
        font-size: 2rem;
        font-weight: 900;
        content: counters(section, ".") " ";
      }
    }
  }

  .note {
    margin-top: 15px;
    font-size: 1.4rem;
  }

  form {
    margin-top: 25px;
    background: rgba(0, 0, 0, 0.6);
    padding: 20px 30px;
    border-radius: 10px;

    h3 {
      margin-bottom: 15px;
      font-size: 2rem;
      color: whitesmoke;
    }

    .two-column {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 15px;
      margin-bottom: 15px;

      .input-group {
        margin-bottom: 0;
      }
    }

    textarea {
      height: 200px;
    }
  }
}

.services {
  padding: 32px 4vw;
  background: white;

  .services-grid {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    margin-top: 16px;
    grid-gap: 15px;
    margin-bottom: 20px;

    .service {
      background: #fff;
      border-radius: 4px;
      box-shadow: 0 1px 16px rgba(0, 0, 0, 0.1);
      padding: 16px;
      font-size: 1.4rem;
      font-weight: 500;
    }
  }
}

.note {
  font-size: 1.4rem;
  font-style: italic;

  @media (min-width: 764px) {
    max-width: 60%;
  }

  &::before {
    content: "*";
    margin-right: 4px;
  }
}
</style>
